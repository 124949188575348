import React from 'react';
import '../styles/Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="footer-section">
        <h4>Suivez-nous</h4>
        <div className="social-links">
          <a href="#"><i className="fab fa-facebook"></i></a>
          <a href="#"><i className="fab fa-instagram"></i></a>
          <a href="#"><i className="fab fa-tiktok"></i></a>
          <a href="#"><i className="fab fa-youtube"></i></a>
        </div>
      </div>
      <div className="footer-section">
        <h4>Outils gratuits</h4>
        <ul>
          <li><a href="#">Tous les outils d'IA</a></li>
          <li><a href="#">Fonds IA</a></li>
          <li><a href="#">Ombres IA</a></li>
          <li><a href="#">IA Agrandir</a></li>
          <li><a href="#">Images IA</a></li>
          <li><a href="#">Retouche magique</a></li>
          <li><a href="#">Suppresseur d'arrière-plan</a></li>
          <li><a href="#">Arrière-plan flou</a></li>
          <li><a href="#">Ajouter du texte à l'image</a></li>
          <li><a href="#">Ajouter un contour à l'image</a></li>
          <li><a href="#">Modèles gratuits</a></li>
          <li><a href="#">Images de fonds gratuites</a></li>
        </ul>
      </div>
      <div className="footer-section">
        <h4>Applications gratuites</h4>
        <ul>
          <li><a href="#">Sur le Web</a></li>
          <li><a href="#">Application iPhone</a></li>
          <li><a href="#">Application Android</a></li>
          <li><a href="#">API (développeurs)</a></li>
          <li><a href="#">Statut de l'API (développeurs)</a></li>
        </ul>
      </div> */}
      <div className="footer-section">
        <h4>Société</h4>
        <ul>
          <li><Link to="/about">À propos de nous</Link></li>
          <li><Link to="/contact">Nous contacter</Link></li>
          {/* <li><a href="#">Tarification</a></li>
          <li><a href="#">Dossier de presse</a></li>
          <li><a href="#">Histoires de clients</a></li>
          <li><a href="#">Blog</a></li>
          <li><a href="#">Carrières</a></li>
          <li><a href="#">Immersion dans Photoroom</a></li>
          <li><a href="#">LinkedIn</a></li>
          <li><a href="#">Contact sales</a></li> */}
        </ul>
      </div>
      <div className="footer-section">
        <h4>Aide et Informations légales</h4>
        <ul>
          {/* <li><a href="#">Centre d'aide</a></li>
          <li><a href="#">Communauté Facebook</a></li>
          <li><a href="#">Conditions générales d'utilisation</a></li> */}
          <li><a href="#">Politique de confidentialité</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
