import React from 'react';
import '../styles/Header.css';
import appLogo from '../assets/logo.png';
import iphoneImage from '../assets/iphone-noshadow.png';

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <img src={iphoneImage} alt="iPhone" className="iphone-image" />
        <div className="text-content">
          <img src={appLogo} alt="App Logo" className="applogo" />
          <p>Décidez où manger en localisant les restaurants à proximité de vous.</p>
          <button onClick={() => window.scrollTo({ top: document.getElementById('qr-code-section').offsetTop, behavior: 'smooth' })}>
            Télécharger
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
