import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isOpen]);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={require('../assets/restoapp.png')} alt="Logo" />
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
      <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <li className="close-menu-icon">
          <FaTimes onClick={toggleMenu} />
        </li>
        <li><Link to="/" onClick={toggleMenu}>Accueil</Link></li>
        <li><Link to="/about" onClick={toggleMenu}>Resto.</Link></li>
        <li><Link to="/contact" onClick={toggleMenu}>Nous contacter</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
