import React from 'react';
import '../styles/About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1 data-aos="fade-up">À propos de Resto.</h1>
      <p data-aos="fade-up">Découvrez votre prochaine expérience culinaire avec Resto. !</p>
      <p data-aos="fade-up">
        Avec Resto., explorer les meilleures options de restauration locales n'a jamais été aussi simple. Que vous soyez à la recherche d'un dîner romantique, d'un brunch convivial ou d'une aventure gastronomique, Resto. vous guide vers les meilleurs restaurants autour de vous.
      </p>
      <h2 data-aos="fade-up">Fonctionnalités clés :</h2>
      <ul>
        <li data-aos="fade-up"><strong>Recherche Intuitive :</strong> Trouvez des restaurants par cuisine, prix, ou ambiance. Que vous soyez d'humeur pour de l'italien authentique, de la cuisine française raffinée ou un burger juteux, votre prochain repas est à quelques clics.</li>
        <li data-aos="fade-up"><strong>Filtres Personnalisés :</strong> Affinez votre recherche avec des filtres personnalisés comme le prix, la distance, les avis des utilisateurs, et plus encore.</li>
        <li data-aos="fade-up"><strong>Cartes et Directions :</strong> Intégration de cartes pour vous montrer le chemin le plus rapide vers une expérience culinaire inoubliable.</li>
        <li data-aos="fade-up"><strong>Horaires d'Ouverture :</strong> Savoir immédiatement si un restaurant est ouvert ou fermé, et planifiez votre visite sans tracas.</li>
        <li data-aos="fade-up"><strong>Favoris :</strong> Enregistrez vos restaurants préférés pour un accès rapide et facile à l'avenir.</li>
        <li data-aos="fade-up"><strong>Évaluations et Avis :</strong> Lisez ce que les autres ont à dire et partagez votre propre expérience pour aider d'autres gourmands.</li>
      </ul>
      <h2 data-aos="fade-up">Pourquoi choisir Resto. ?</h2>
      <ul>
        <li data-aos="fade-up">Interface utilisateur simple et épurée.</li>
        <li data-aos="fade-up">Mises à jour régulières avec de nouvelles fonctionnalités et améliorations.</li>
        <li data-aos="fade-up">Un service client réactif et dédié pour répondre à vos questions.</li>
      </ul>
      <p data-aos="fade-up">
        Que vous soyez un local ou juste de passage, Resto. est votre compagnon idéal pour découvrir les meilleurs restaurants autour de vous. Téléchargez Resto. aujourd'hui et commencez votre voyage culinaire !
      </p>
    </div>
  );
};

export default About;
