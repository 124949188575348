import React from 'react';
import '../styles/MainContent.css';
import appStoreLogo from '../assets/apple-logo.png';
import googlePlayLogo from '../assets/playstore-logo.png';
import QRCode from 'react-qr-code';

const appLink = "https://apps.apple.com/be/app/resto/id6526463807?l=fr-FR"

const handleButtonClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

const MainContent = () => {
  return (
    <section id="qr-code-section" className="main-content">
      <h2>Téléchargez l'application</h2>
      <div className="content-container">
        <img src={require('../assets/logo-for-site-app-resto.png')} alt="App Logo" className="app-logo" />
        <div className="qr-code">
          <QRCode value={appLink} size={150} />
        </div>
      </div>
      <div className="download-buttons">
        <button className="download-button" onClick={() => handleButtonClick(appLink)}>
          <img src={appStoreLogo} alt="App Store" />
          <div className="download-button-text">
            <span className="small-text">Télécharger sur</span>
            <span className="large-text">App Store</span>
          </div>
        </button>
        {/* <button className="download-button">
          <img src={googlePlayLogo} alt="Google Play" />
          <div className="download-button-text">
            <span className="small-text">Disponible sur</span>
            <span className="large-text">Google Play</span>
          </div>
        </button> */}
      </div>
    </section>
  );
};

export default MainContent;
